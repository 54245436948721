import React from 'react';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  FormFeedback,
  Label,
  Input
} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import APIs from 'APIs';
import validator from 'validator';

const defaultState = {
  name: '',
  nameError: '',
  email: '',
  emailError: '',
  password: '',
  passwordError: '',
  alert: {
    type: '',
    message: ''
  },
  role: 'virtual assistant',
  adding: false
};
export default class AddVirtualAssistantModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    const { assistant } = this.props;
    if (assistant) {
      this.setState({ name: assistant.name, email: assistant.email });
    }
  }

  onClosed = () => {
    this.setState(defaultState);
  }

  onAdd = () => {
    const { name, email, password, role } = this.state;
    const { assistant } = this.props;
    if (!name) {
      this.setState({ nameError: 'Name is required field' });
      return;
    }
    if (!validator.isEmail(email)) {
      this.setState({ emailError: 'Invalid email detected' });
      return;
    }
    if (!assistant && !password) {
      this.setState({ passwordError: 'Password is required field' });
      return;
    }

    this.setState({ adding: true });
    if (!assistant) {
      APIs.addVirtualAssistant({ name, email, password, role }).then(resp => {
        this.setState({ adding: false });
        const { data } = resp.data;
        if (this.props.onAddedVA) {
          this.props.onAddedVA(data);
        }
      }).catch(err => {
        this.setState({ adding: false, alert: {
          type: 'danger',
          message: err.response ? err.response.data.message : err.message
        } });

      });
    } else {
      APIs.editVirtualAssistant({ id: assistant.id, name, email, password, role }).then(resp => {
        this.setState({ adding: false });
        const { data } = resp.data;
        if (this.props.onEditedVA) {
          this.props.onEditedVA(data);
        }
      }).catch(err => {
        this.setState({ adding: false, alert: {
          type: 'danger',
          message: err.response ? err.response.data.message : err.message
        } })
      });
    }
  }

  render() {
    const { isOpen, toggle, onCancel } = this.props;
    const {
      name,
      nameError,
      email,
      emailError,
      password,
      passwordError,
      role,
      adding,
      alert
    } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle} onExit={this.onExit} centered>
        <ModalHeader>
          <h6 className='mb-0'>Add Virtual Assistant</h6>
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label>Name</Label>
            <Input
              type='text'
              value={name}
              onChange={event => this.setState({ name: event.target.value, nameError: '' })}
              invalid={nameError ? true : false}
            />
            <FormFeedback>{nameError}</FormFeedback>
          </FormGroup>
          <FormGroup row>
            <Label>Email</Label>
            <Input
              type='text'
              value={email}
              defaultValue=''
              onChange={event => this.setState({ email: event.target.value, emailError: '' })}
              invalid={emailError ? true : false}
            />
            <FormFeedback>{emailError}</FormFeedback>
          </FormGroup>
          <FormGroup row>
            <Label>Password</Label>
            <Input
              type='password'
              value={password}
              defaultValue=''
              onChange={event => this.setState({ password: event.target.value, emailError: '' })}
              invalid={passwordError ? true : false}
            />
            <FormFeedback>{passwordError}</FormFeedback>
          </FormGroup>
          <FormGroup row>
            <Label>Role</Label>
            <Input
              type='select'
              value={role}
              onChange={event => this.setState({ role: event.target.value })}
            >
            <option value='virtual assistant'>Virtual Assistant</option>
            </Input>
          </FormGroup>
          { alert.message && <Alert color={alert.type}>{alert.message}</Alert> }
        </ModalBody>
        <ModalFooter>
          <Button className='pull-right bg-green text-white border-0'
            loading={adding}
            disabled={adding}
            onClick={this.onAdd}>
            {this.props.assistant ? 'Edit' : 'Add'}
          </Button>
          <Button className='pull-right btn-secondary border-0'
            onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
