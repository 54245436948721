import { handleActions } from 'redux-actions';
const initialState = {
  id: 0,
  name: undefined,
  email: undefined,
  role: '',
  createdAt: undefined
}

export default handleActions({
  updateUser: (state, action) => ({ ...state, ...action.payload })
}, initialState);
