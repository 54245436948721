import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import logger from 'redux-logger';
import user from './user/reducers';

export default function configureStore() {
  const middlewares = [logger];
  const middlewareEnhancers = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancers];
  const composedEnhancers = compose(...enhancers);

  const rootReducers = combineReducers({
    user
  });

  const store = createStore(rootReducers, undefined, composedEnhancers);
  return store;
}
