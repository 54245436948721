import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const AlertModal = ({ isOpen, handleClose, title, message, onClickPositive }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">{title}</h2>
          <p id="transition-modal-description" className='font-montserrat'>{message}</p>
          <div className='row pull-right'>
            <Button color='secondary' variant="contained" onClick={onClickPositive}>Yes</Button>
            <Button onClick={handleClose}>No</Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default AlertModal;
