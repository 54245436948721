import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import Button from 'reactstrap-button-loader';

export const AlertModal = ({
  isOpen, title, toggle, message, posBtnTitle,
  negBtnTitle, onClkPosBtn, onClkNegBtn, loading }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader>
        {title}
      </ModalHeader>
      <ModalBody>
        <h6 className='mb-0'>
        {message}
        </h6>
      </ModalBody>
      <ModalFooter>
        <Button className='pull-right bg-red border-0 text-white'
          onClick={onClkPosBtn}
          loading={loading}>
          {posBtnTitle ? posBtnTitle : 'Yes'}
        </Button>
        <Button className='pull-right'
          onClick={onClkNegBtn}>
          {negBtnTitle ? negBtnTitle : 'No'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
