import axios from 'axios';
import dotenv from 'dotenv';
dotenv.config();

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
});

apiInstance.interceptors.response.use(resp => {
  return resp;
}, err => {
  if (err.response) {
    console.log(err.response);
  }
  return Promise.reject(err);
})

class APIs {
  adminLogin = (params) => apiInstance.post('adminLogin', params);
  getAdminData = (params) => apiInstance.get('getAdminData', { params });
  getDashboardData = () => apiInstance.get('getDashboardData');
  getFeedbacks = () => apiInstance.get('getFeedbacks');
  deleteFeedback = (params) => apiInstance.delete('deleteFeedback', { data: params });
  getUsers = (params) => apiInstance.get('getUsers', { params });
  sendFeedbackReply = (params) => apiInstance.post('sendFeedbackReply', params);
  getProfits = (params) => apiInstance.get('getProfits', { params });
  changeAdminPassword = (params) => apiInstance.post('changeAdminPassword', params);
  getVirtualAssistants = (params) => apiInstance.get('getVirtualAssistants', { params });
  addVirtualAssistant = (params) => apiInstance.post('addVirtualAssistant', params);
  editVirtualAssistant = (params) => apiInstance.post('editVirtualAssistant', params);
  removeVirtualAssistant = (params) => apiInstance.delete('removeVirtualAssistant', { data: params });
  addCoupon = (params) => apiInstance.post('addCoupon', params);
  editCoupon = (params) => apiInstance.post('editCoupon', params);
  getCoupons = (params) => apiInstance.get('getCoupons', { params });
  deleteCoupon = (params) => apiInstance.delete('deleteCoupon', { data: params });
  getCouponUsages = (params) => apiInstance.get('getCouponUsages', { params });
  getTwilioBalanceWithoutAuth = (params) => apiInstance.get('getTwilioBalanceWithoutAuth', { params });
}

export default new APIs();
