import React from 'react';
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import isEmail from 'validator/lib/isEmail';
import Button from 'reactstrap-button-loader';
import { Slide } from 'react-reveal';
import APIs from 'APIs';
import ls from 'local-storage';
import { connect } from 'react-redux';
import { updateUser } from 'redux/user/actions.js';

const defaultState = {
  email: '',
  emailError: '',
  password: '',
  passwordError: '',
  isLoggingIn: false,
  willRemember: false
};
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {

  }

  adminLogin = () => {
    const { email, password } = this.state;
    if (!isEmail(email)) {
      this.setState({ emailError: 'Please input a valid email' });
      return;
    }
    if (!password) {
      this.setState({ passwordError: 'Password cannot be empty' });
      return;
    }
    this.setState({ isLoggingIn: true });

    APIs.adminLogin({ email, password }).then(resp => {
      // console.log("User login: ", resp.data);
      this.setState({ isLoggingIn: false });
      this.props.updateUser(resp.data.data);
      ls.set('isLoggedIn', true);
      ls.set('userId', resp.data.data.id);
      this.props.history.push('main');
    }).catch(err => {
      this.setState({ isLoggingIn: false });
      if (err.response) {
        const errData = err.response.data;
        const { error_field, message } = errData;
        if (error_field === 'email') {
          this.setState({ emailError: message });
        } else if (error_field === 'password') {
          this.setState({ passwordError: message });
        }
      }
    });
  }

  render() {
    const {
      email,
      emailError,
      password,
      passwordError,
      isLoggingIn,
      willRemember
    } = this.state;
    return (
      <>
        <main ref="main">
          <Container className="pt-lg-md">
            <Row className="justify-content-center">
              <Col lg="5">
                <Slide top>
                  <Card className="bg-secondary shadow border-0 my-5">
                    <CardBody className="px-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small className='font-montserrat'>Sign in with admin credentials</small>
                      </div>
                      <Form role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input className='font-montserrat' placeholder="Email" type="email" value={email}
                              onChange={event => this.setState({ email: event.target.value, emailError: '' })}
                              invalid={emailError ? true : false} />
                            <FormFeedback className='font-montserrat'>{emailError}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              autoComplete="off"
                              className='font-montserrat'
                              value={password}
                              onChange={event => this.setState({ password: event.target.value, passwordError: '' })}
                              invalid={passwordError ? true : false}
                            />
                            <FormFeedback className='font-montserrat'>{passwordError}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="customCheckLogin"
                            type="checkbox"
                            checked={willRemember}
                            onChange={() => this.setState({ willRemember: !this.state.willRemember })}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckLogin"
                          >
                            <span className='font-montserrat'>Remember me</span>
                          </label>
                        </div>
                        <div className="text-center">
                          <Button
                            className="my-4 text-white bg-green border-0 font-montserrat"
                            onClick={this.adminLogin}
                            loading={isLoggingIn}>
                            Sign in
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Slide>
              </Col>
            </Row>
          </Container>
        </main>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToPros = dispatch => ({
  updateUser: (user) => dispatch(updateUser(user))
});

export default connect(mapStateToProps, mapDispatchToPros)(Login);
