import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import moment from 'moment';

// reactstrap components
import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  Col
} from "reactstrap";

class Datepicker extends React.Component {
  render() {
    const { label, onDateChanged, value, disabled, placeholder } = this.props;
    return (
      <FormGroup row>
        <Label sm='3'><small>{label}</small></Label>
        <Col>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className={disabled ? 'bg-disabled' : 'bg-white'}>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
              timeFormat={false}
              dateFormat='YYYY-MM-DD'
              utc={true}
              value={value}
              className='rdt-date-picker'
              inputProps={{ disabled, placeholder }}
              onChange={e => {
                if (onDateChanged) {
                  onDateChanged(moment(e).format('YYYY-MM-DD'));
                }
              }
              }
            />
          </InputGroup>
        </Col>
      </FormGroup>
    );
  }
}

export default Datepicker;
