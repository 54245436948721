import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import Login from './screens/Login.js';
import Main from './screens/Main.js';
import history from './history.js';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/styles.scss';
import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/font-awesome/css/font-awesome.css';

import configureStore from './redux/index.js';
const store = configureStore();

const rootElement = document.getElementById("root");

const rootRender = (
  <Provider store={store}>
    <HttpsRedirect>
      <Router history={history}>
        <Switch>
          <Route
            path='/'
            exact
            render={props => <Login {...props} />} />
          <Route
            path='/main'
            exact
            render={props => <Main {...props} />} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </HttpsRedirect>
  </Provider>
);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(rootRender, rootElement);
} else {
  ReactDOM.render(rootRender, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
