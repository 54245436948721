import React from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = ({ navDrawerOpen, title, handleChangeRequestNavDrawer }) => {

  const classes = useStyles();
  return (
    <AppBar
      className={clsx(classes.appBar, navDrawerOpen && classes.appBarShift)}
      position='absolute'>
      <Toolbar className={classes.toolbar}>
        <IconButton edge='start' color='inherit' aria-label='Menu' onClick={handleChangeRequestNavDrawer}
          className={clsx(classes.menuButton, navDrawerOpen && classes.menuButtonHidden)}>
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          <span className='font-montserrat'>
          {title}
          </span>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
export default Header;
