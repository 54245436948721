import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Col,
  Input,
  FormFeedback,
  Label
} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import APIs from 'APIs';
import Datepicker from 'components/Datepicker.js';
import _ from 'lodash';
import moment from 'moment';

const defaultState = {
  adding: false,
  name: '',
  nameErr: '',
  coupon: '',
  couponErr: '',
  percent: '',
  percentErr: '',
  startDate: null,
  endDate: null,
  status: 'active'
}
export default class AddCouponModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    const { coupon } = this.props;
    if (coupon) {
      this.setState({
        name: coupon.name,
        coupon: coupon.coupon,
        percent: coupon.percentOff,
        startDate: coupon.startDate ? moment(coupon.startDate).format('YYYY-MM-DD') : null,
        endDate: coupon.endDate ? moment(coupon.endDate).format('YYYY-MM-DD') : null,
        status: coupon.status
      });
    }
  }

  onExit = () => {
    this.setState(defaultState);
  }

  onAdd = () => {
    const { name, coupon, percent, startDate, endDate, status } = this.state
    if (!name) {
      this.setState({ nameErr: 'Name cannot be empty' });
      return;
    }
    if (!coupon) {
      this.setState({ couponErr: 'Coupon cannot be empty' });
      return;
    }
    if (!percent || !_.toInteger(percent) || _.toInteger(percent) > 100)  {
      this.setState({ percentErr: 'Invalid percent detected' });
      return;
    }

    this.setState({ adding: true });
    if (this.props.coupon) {
      const params = {
        id: this.props.coupon.id,
        stripeCouponId: this.props.coupon.stripeCouponId,
        name,
        coupon,
        percent,
        startDate,
        endDate,
        status
      };

      APIs.editCoupon(params).then(resp => {
        this.setState({ adding: false });
        if (this.props.onCouponEdited) {
          this.props.onCouponEdited({ ...this.props.coupon, ...params });
        }
      }).catch(err => {
        this.setState({ adding: false });
      });
    } else {
      const params = {
        name,
        coupon,
        percent,
        startDate,
        endDate,
        status
      };
      APIs.addCoupon(params).then(resp => {
        this.setState({ adding: false });
        if (this.props.onCouponAdded) {
          this.props.onCouponAdded(resp.data.data);
        }
      }).catch(err => {
        this.setState({ adding: false });
      });
    }
  }

  render() {
    const { isOpen, toggle } = this.props;
    const {
      adding,
      name,
      nameErr,
      coupon,
      couponErr,
      percent,
      percentErr,
      startDate,
      endDate,
      status
    } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={toggle} onExit={this.onExit} centered>
        <ModalHeader>
          Add Coupon
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label sm='3'><small>Name</small></Label>
            <Col>
              <Input type='text'
                value={name}
                placeholder='The name of this discount'
                onChange={(event) => this.setState({ name: event.target.value, nameErr: '' })}
                invalid={nameErr ? true : false} />
              <FormFeedback>{nameErr}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm='3'><small>Coupon</small></Label>
            <Col>
              <Input type='text'
                value={coupon}
                placeholder='Enter a code for this discount.'
                onChange={(event) => this.setState({ coupon: event.target.value, couponErr: '' })}
                invalid={couponErr ? true : false} />
              <FormFeedback>{couponErr}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm='3'><small>Percent Off</small></Label>
            <Col>
              <Input type='text'
                value={percent}
                placeholder='Enter the discount percentage. 10=10%.'
                onChange={event => this.setState({ percent: event.target.value, percentErr: '' })}
                invalid={percentErr ? true : false}
                disabled={this.props.coupon ? true : false} />
              <FormFeedback>{percentErr}</FormFeedback>
            </Col>
          </FormGroup>
          <Datepicker
            label='Start Date'
            onDateChanged={date => this.setState({ startDate: date })}
            placeholder='For no start date, leave blank.'
            value={startDate}
            disabled={this.props.coupon ? true : false} />
          <Datepicker
            label='End Date'
            onDateChanged={date => this.setState({ endDate: date })}
            placeholder='For no expiration, leave blank.'
            value={endDate}
            disabled={this.props.coupon ? true : false} />
          <FormGroup row>
            <Label sm='3'><small>Status</small></Label>
            <Col>
              <Input type='select'
                value={status}
                onChange={event => this.setState({ status: event.target.value })}>
                <option value='active'>Active</option>
                <option value='inactive'>Inactive</option>
                <option value='expired'>Expired</option>
              </Input>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className='pull-right bg-green text-white border-0'
            loading={adding}
            disabled={adding}
            onClick={this.onAdd}>
            {this.props.coupon ? 'Edit' : 'Add'}
          </Button>
          <Button className='pull-right btn-secondary border-0'
            onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
