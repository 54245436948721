import React from "react";
import { Paper } from "@material-ui/core";
import { pink } from "@material-ui/core/colors";
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from "recharts";

const MonthlySales = props => {
  const styles = {
    paper: {
      backgroundColor: pink[600],
      height: 350
    },
    div: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      height: 300
    },
    header: {
      color: 'white',
      backgroundColor: pink[500],
      padding: 10
    }
  };

  return (
    <Paper style={styles.paper}>
      <div className='font-montserrat' style={{ ...styles.header }}>
        Monthly Profits
      </div>
      <div style={styles.div}>
        <ResponsiveContainer>
          <BarChart data={props.data}>
            <Bar dataKey="profit" fill={pink[400]} />
            <XAxis
              dataKey="name"
              stroke="none"
              tick={{ fill: 'white', fontFamily: 'Montserrat' }} />
            <YAxis
              tick={{ fill: 'white', fontFamily: 'Montserrat' }}
              allowDecimals={false}
              axisLine={false}
              tickLine={false} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};

export default MonthlySales;
