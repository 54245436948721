import {blue, grey} from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
const themeDefault = createMuiTheme({
  palette: {
  },
  appBar: {
    height: 57,
    color: blue[600]
  },
  drawer: {
    width: 230,
    color: grey[900]
  },
  raisedButton: {
    primaryColor: blue[600],
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto'
    ]
  }
});


export default themeDefault;
