import React from 'react';
import { Redirect } from 'react-router-dom';
import Header from 'components/Header.js';
import LeftDrawer from 'components/LeftDrawer.js';
import AlertModal from 'components/AlertModal.js';
import { ThemeProvider } from '@material-ui/core/styles';
import ThemeDefault from '../theme-default';
// import FontIcon from 'material-ui/FontIcon';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import FaceIcon from '@material-ui/icons/Face';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsIcon from '@material-ui/icons/Settings';
import MoneyIcon from '@material-ui/icons/Money';
import Dashboard from './Dashboard.js';
import Customers from './Customers.js';
import VirtualAssistants from './VirtualAssistants.js';
import Profits from './Profits.js';
import Coupons from './Coupons.js';
import CouponUsage from './CouponUsage.js';
import Settings from './Settings.js';
import ls from 'local-storage';
import { connect } from 'react-redux';
import { updateUser } from 'redux/user/actions';
import APIs from 'APIs';


const adminMenus = [
  { text: "Dashboard", icon: <AssessmentIcon /> },
  { text: "Customers", icon: <PermIdentityIcon /> },
  { text: "Virtual Assistants", icon: <FaceIcon />},
  { text: "Profits", icon: <AttachMoneyIcon /> },
  { text: "Coupons", icon: <MoneyIcon /> },
  { text: "Settings", icon: <SettingsIcon /> },
  { text: "Sign out", icon: <SettingsPowerIcon /> }
];

const vaMenus = [
  { text: "Dashboard", icon: <AssessmentIcon /> },
  { text: "Customers", icon: <PermIdentityIcon /> },
  { text: "Sign out", icon: <SettingsPowerIcon /> }
]
const signOutMenus = [
  { text: "Change password", icon: <VpnKeyIcon />, link: "" }
]
const defaultState = {
  navDrawerOpen: true,
  activePage: 'Dashboard',
  showSignoutModal: false,
  coupon: undefined
}
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    if (ls.get('userId') && ls.get('isLoggedIn')) {
      const adminId = ls.get('userId');
      this.getAdminData(adminId);
    }
  }

  handleChangeRequestNavDrawer() {
    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen
    });
  }

  onShowCouponUsage = (coupon) => {
    this.setState({ activePage: 'Coupon Usage', coupon });
  }

  getAdminData = (adminId) => {
    if (adminId) {
      APIs.getAdminData({ adminId }).then(resp => {
        if (resp.data.success) {
          this.props.updateUser(resp.data.data);
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }

  onSignOut = () => {
    ls.set('isLoggedIn', false);
    ls.set('userId', 0);
    this.props.updateUser({
      id: 0,
      name: undefined,
      email: undefined,
      role: '',
      createdAt: undefined
    });
    this.props.history.push('/');
  }

  render() {
    let { navDrawerOpen, activePage, showSignoutModal, coupon } = this.state;
    const { user } = this.props;

    return (
      (!ls.get('isLoggedIn') || !ls.get('userId')) ? <Redirect to={{ pathname: '/' }}  /> :
      <ThemeProvider theme={ThemeDefault}>
        <div className='d-flex'>
          <Header
            navDrawerOpen={navDrawerOpen}
            title={activePage}
            handleChangeRequestNavDrawer={this.handleChangeRequestNavDrawer.bind(
              this
            )}
          />
          {
            <LeftDrawer
              navDrawerOpen={navDrawerOpen}
              handleDrawerClose={this.handleChangeRequestNavDrawer.bind(this)}
              menus={(user && user.role === 'admin') ? adminMenus : vaMenus}
              signOutMenus={signOutMenus}
              username={'Daniels'}
              onMenuChanged={(menu) => {
                if (menu.text !== 'Sign out') {
                  this.setState({ activePage: menu.text });
                } else {
                  this.setState({ showSignoutModal: true });
                }
              }}
              onLogoutClick={() => {}}
            />
          }
          <div className='mt-64 w-100 px-5'>
            {activePage === 'Dashboard' && <Dashboard />}
            {activePage === 'Customers' && <Customers />}
            {activePage === 'Virtual Assistants' && <VirtualAssistants />}
            {activePage === 'Profits' && <Profits />}
            {activePage === 'Coupons' && <Coupons onShowCouponUsage={this.onShowCouponUsage} />}
            {activePage === 'Coupon Usage' && <CouponUsage coupon={coupon} />}
            {activePage === 'Settings' && <Settings />}
            {
              showSignoutModal &&
              <AlertModal
                isOpen={showSignoutModal}
                handleClose={() => this.setState({ showSignoutModal: false })}
                title='Sign Out'
                message='Are you sure you want to sign out?'
                onClickPositive={this.onSignOut} />
            }
          </div>
        </div>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToPros = dispatch => ({
  updateUser: (user) => dispatch(updateUser(user))
});

export default connect(mapStateToProps, mapDispatchToPros)(Main);
