import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import APIs from 'APIs';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';

const FILTER_ITEM_HEIGHT = 48;
const defaultState = {
  isLoading: false,
  offset: 0,
  rowsPerPage: 10,
  activePage: 0,
  totalProfitCount: 0,
  profits: [],
  filterCriteria: {
    paymentReason: [],
    createdDate: ''
  },
  planFilterAnchorEl: null,
  dateFilterAnchorEl: null
};
export default class Profits extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    const { offset, rowsPerPage } = this.state;
    this.getProfits(offset, rowsPerPage);
  }

  getProfits = (offset, limit) => {
    const { totalProfitCount, filterCriteria } = this.state;
    if (totalProfitCount !== 0 && offset >= totalProfitCount) {
      return;
    }
    this.setState({ isLoading: true });
    APIs.getProfits({ offset, limit, filter: filterCriteria }).then(resp => {
      this.setState({
        isLoading: false,
        offset: offset + resp.data.data.length,
        profits: offset ? [...this.state.profits, ...resp.data.data] : resp.data.data,
        totalProfitCount: resp.data.totalProfitCount ?? this.state.totalProfitCount
      });
    }).catch(err => {
      console.log(err);
    });
  }

  onChangePage = (event, newPage) => {
    const { activePage } = this.state;
    if (newPage > activePage) {
      this.setState({ activePage: newPage }, () => {
        const { offset, rowsPerPage } = this.state;
        this.getProfits(offset, rowsPerPage);
      });
    } else {
      this.setState({ activePage: newPage });
    }
  }

  onChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      activePage: 0,
      offset: 0
    }, () => {
      const { offset, rowsPerPage } = this.state;
      this.getProfits(offset, rowsPerPage);
    });
  }

  renderPaymentReasonFilterMenu = () => {
    const { planFilterAnchorEl } = this.state;
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => this.setState({ planFilterAnchorEl: event.currentTarget })}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={planFilterAnchorEl}
          keepMounted
          open={Boolean(planFilterAnchorEl)}
          onClose={() => this.setState({ planFilterAnchorEl: null })}
          PaperProps={{
            style: {
              maxHeight: FILTER_ITEM_HEIGHT * 4.5,
            },
          }}>
          {
            _.map(['Add Balance', 'Storage'], (reason) => {
              return (
                <MenuItem key={reason}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={_.includes(this.state.filterCriteria.paymentReason, reason)}
                        onChange={event => {
                          let { paymentReason } = this.state.filterCriteria;
                          if (event.target.checked) {
                            paymentReason.push(reason);
                          } else {
                            _.remove(paymentReason, i => i === reason);
                          }
                          this.setState({
                            filterCriteria: {
                              ...this.state.filterCriteria,
                              paymentReason
                            },
                            offset: 0,
                            activePage: 0
                          }, () => {
                            this.getProfits(0, this.state.rowsPerPage);
                          });
                        }} />
                    }
                    label={reason}
                    />
                </MenuItem>
              )
            })
          }
        </Menu>
      </>
    )
  };

  renderFilterMenu = (anchorEl, options) => {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className='p-1'
          onClick={(event) => {
            let state = this.state;
            state[anchorEl] = event.currentTarget;
            this.setState(state);
          }}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state[anchorEl]}
          keepMounted
          open={Boolean(this.state[anchorEl])}
          onClose={() => {
            let state = this.state;
            state[anchorEl] = null;
            this.setState(state);
          }}
          PaperProps={{
            style: {
              maxHeight: FILTER_ITEM_HEIGHT * 4.5,
            },
          }}>
          {
            _.map(options, (option) => {
              return (
                <MenuItem
                  key={option}
                  label={option}
                  onClick={() => {
                    let state = this.state;
                    if (anchorEl === 'dateFilterAnchorEl') {
                      state['filterCriteria']['createdDate'] = option;
                    }
                    state[anchorEl] = null;
                    state['offset'] = 0;
                    state['activePage'] = 0;
                    this.setState(state, () => {
                      this.getProfits(0, this.state.rowsPerPage);
                    });
                  }}>
                  {option}
                </MenuItem>
              )
            })
          }
        </Menu>
      </>
    )
  }

  renderDateFilterMenu = () => {
    return this.renderFilterMenu('dateFilterAnchorEl', ['This Month', 'This Week', 'Today', 'Yesterday', 'Last 7 days', 'Last 14 days', 'Last 30 days', 'All']);
  }

  render() {
    const { isLoading, totalProfitCount, rowsPerPage, activePage, profits, filterCriteria } = this.state;
    return (
      <div>
        <LoadingOverlay active={isLoading} spinner>
          <TableContainer className='border border-1 mt-5'>
            <Table>
              <TableHead className='bg-secondary'>
                <TableRow>
                  {
                    _.map(['ID', 'User name', 'Payment Reason', 'Amount', 'Created Date'], (field) => (
                      <TableCell key={field}>
                        {field}
                        {
                          field === 'Payment Reason' &&
                          this.renderPaymentReasonFilterMenu()
                        }
                        {
                          field === 'Payment Reason' && filterCriteria['paymentReason'].length > 0 &&
                          <div>
                          {
                            _.map(filterCriteria['paymentReason'], reason => (
                              <small key={reason}>
                                {reason}{' '}
                              </small>
                            ))
                          }
                          </div>
                        }
                        {
                          field === 'Created Date' &&
                          this.renderDateFilterMenu()
                        }
                        {
                          field === 'Created Date' && filterCriteria['createdDate'] && filterCriteria['createdDate'] !== 'All' &&
                          <div>
                            <small>
                            {filterCriteria['createdDate']}
                            </small>
                          </div>
                        }
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  profits.length === 0 &&
                  <TableRow>
                    <TableCell>No record found</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                }
                {
                  (rowsPerPage > 0 ?
                    profits.slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage )
                    : profits
                  ).map((profit, index) => {
                    return (
                      <TableRow key={profit.id}>
                        <TableCell>{index + 1 + (rowsPerPage * activePage)}</TableCell>
                        <TableCell>{profit.name}</TableCell>
                        <TableCell>{profit.paymentReason}</TableCell>
                        <TableCell>${parseFloat(profit.amountPaid).toFixed(2)}</TableCell>
                        <TableCell>{moment(profit.createdAt).format('DD MMM, YYYY HH:mm')}</TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
              <TableFooter className='bg-secondary'>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={totalProfitCount}
                    rowsPerPage={rowsPerPage}
                    page={activePage}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </LoadingOverlay>
      </div>
    )
  }
}
