import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  IconButton,
} from '@material-ui/core';
import { pink, cyan, purple, teal, orange } from '@material-ui/core/colors'
import InfoBox from 'components/dashboard/InfoBox.js'
import FavoriteIcon from '@material-ui/icons/Favorite';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ReplyIcon from '@material-ui/icons/Reply';
import MonthlySales from 'components/dashboard/MonthlySales.js';
import MonthlyNewUsers from 'components/dashboard/MonthlyNewUsers.js';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertModal from '../components/AlertModal.js';
import ReplyModal from '../components/ReplyModal.js';
import { connect } from 'react-redux';
import APIs from 'APIs';
import _ from 'lodash';
import moment from 'moment';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug",
                    "Sept", "Oct", "Nov", "Dec"];
const defaultState = {
  totalProfit: 0,
  annualProfit: 0,
  monthlyProfits: [],
  totalUserCount: 0,
  paidUserCount: 0,
  monthlyNewUsers: [],
  feedbacks: [],
  rowsPerPage: 5,
  activePage: 0,
  showDeleteFeedbackModal: false,
  activeFeedback: undefined,
  showReplyModal: false
};
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    this.getDashboardData();
    this.getFeedbacks();
  }

  getDashboardData = () => {
    APIs.getDashboardData().then(resp => {
      console.log(resp.data);
      const { profit, user } = resp.data.data;
      this.monthlySales = _.map(profit.monthlyProfits, (value, key) => {
        const monthName = _.split(key, '-')[1];
        return { name: monthNames[parseInt(monthName) - 1], profit: value };
      });
      this.monthlySales = _.reverse(this.monthlySales);
      this.monthlyNewUsers = _.map(user.monthlyNewUsers, (value, key) => {
        const monthName = _.split(key, '-')[1];
        return { name: monthNames[parseInt(monthName) - 1], count: value };
      });
      this.monthlyNewUsers = _.reverse(this.monthlyNewUsers);
      this.setState({
        totalProfit: profit.totalProfit,
        annualProfit: profit.annualProfit,
        monthlyProfits: profit.monthlyProfits,
        totalUserCount: user.totalUserCount,
        paidUserCount: user.paidUserCount,
        monthlyNewUsers: user.monthlyNewUsers
      });

    }).catch(err => {
      console.log(err);
    });
  }

  getFeedbacks = () => {
    APIs.getFeedbacks().then(resp => {
      const sortedFeedback = _.reverse(_.sortBy(resp.data.data, o => o.createdAt));
      this.setState({ feedbacks: sortedFeedback });
    }).catch(err => {
      console.log(err);
    });
  }

  getThisMonthProfit = () => {
    const { monthlyProfits } = this.state;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    let monthlyProfit = monthlyProfits[`${currentYear}-${currentMonth}`];
    if (monthlyProfit) {
      monthlyProfit = parseFloat(monthlyProfit).toFixed(2);
    }
    return monthlyProfit;
  }

  onReplyFeedback = (feedback) => {
    this.setState({ showReplyModal: true, activeFeedback: feedback });
  }

  onSendReply = (reply) => {
    console.log('Reply text: ', reply);
    const { activeFeedback } = this.state;
    if (!reply) {
      return;
    }
    APIs.sendFeedbackReply({ feedbackId: activeFeedback.id, message: reply }).then(resp => {
      this.setState({ showReplyModal: false, activeFeedback: undefined });
    }).catch(err => {
      console.log(err);
    });
  }

  onDeleteFeedback = (feedback) => {
    this.setState({
      showDeleteFeedbackModal: true,
      activeFeedback: feedback
    });
  }

  onConfirmDeleteFeedback = () => {
    const { feedbacks, activeFeedback } = this.state;
    APIs.deleteFeedback({ feedbackId: activeFeedback.id }).then(resp => {
      this.setState({ showDeleteFeedbackModal: false });
      if (resp.data.success) {
        _.remove(feedbacks, (feedback) => feedback.id === activeFeedback.id);
        this.setState({ feedbacks, activeFeedback: undefined });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  onChangePage = (event, newPage) => {
    this.setState({ activePage: newPage });
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), activePage: 0 });
  }

  render() {
    const {
      totalProfit,
      annualProfit,
      totalUserCount,
      paidUserCount,
      feedbacks,
      rowsPerPage,
      activePage,
      showDeleteFeedbackModal,
      showReplyModal,
      activeFeedback
    } = this.state;
    const { user } = this.props;
    return (
      <div className='mt-5'>
        <div className="row">
          {
            user.role === 'admin' &&
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg m-b-15">
              <InfoBox
                Icon={AttachMoneyIcon}
                color={pink[600]}
                title="Total Profit"
                value={ totalProfit > 10000 ? `${parseFloat(totalProfit / 1000).toFixed(2)}K` : parseFloat(totalProfit).toFixed(2) }
              />
            </div>
          }
          {
            user.role === 'admin' &&
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg m-b-15 mt-1 mt-lg-0">
              <InfoBox
                Icon={AccountBalanceWalletIcon}
                color={teal[600]}
                title="Annual Profit"
                value={annualProfit > 10000 ? `${parseFloat(annualProfit / 1000).toFixed(2)}K` : parseFloat(annualProfit).toFixed(2)}
              />
            </div>
          }
          {
            user.role === 'admin' &&
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg m-b-15 mt-1 mt-lg-0">
              <InfoBox
                Icon={AssessmentIcon}
                color={purple[600]}
                title="Monthly Profit"
                value={this.getThisMonthProfit() ?? '0'}
              />
            </div>
          }
          <div className={user.role === 'admin' ? "col-xs-12 col-sm-12 col-md-12 col-lg m-b-15 mt-1 mt-lg-0" : "col-xs-12 col-sm-6 col-md-6 col-lg-6 m-b-15"}>
            <InfoBox
              Icon={PeopleIcon}
              color={cyan[600]}
              title="Total Users"
              value={totalUserCount} />
          </div>

          <div className={user.role === 'admin' ? "col-xs-12 col-sm-12 col-md-12 col-lg m-b-15 mt-1 mt-lg-0" : "col-xs-12 col-sm-6 col-md-6 col-lg-6 m-b-15"}>
            <InfoBox
              Icon={FavoriteIcon}
              color={orange[600]}
              title="Paid Users"
              value={paidUserCount}
            />
          </div>
        </div>
        <div className="row mt-5">
          {
            user.role === 'admin' &&
            <div className="col-xs-12 col-lg-6 m-b-15">
              <MonthlySales data={this.monthlySales} />
            </div>
          }
          <div className={user.role === 'admin' ? "col-xs-12 col-lg-6 col-md m-b-15 mt-2 mt-lg-0" : "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-md m-b-15"}>
            <MonthlyNewUsers data={this.monthlyNewUsers} />
          </div>
        </div>
        {
          feedbacks.length > 0 &&
          <div>
            <h4 className='mt-5'>
              Feedbacks from customers
            </h4>
            <TableContainer className='my-2 border border-1'>
              <Table>
                <TableHead className='bg-secondary'>
                  <TableRow>
                    {_.map(['Name', 'Email', 'Subject', 'Message', 'Date', 'Action'], row => {
                      return (
                        <TableCell key={row}>
                          <span className='font-montserrat'>
                            {row}
                          </span>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    (rowsPerPage > 0 ?
                      feedbacks.slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage )
                      : feedbacks
                    ).map((feedback) => {
                      return (
                        <TableRow key={feedback.id}>
                          <TableCell width='15%'><span className='font-montserrat'>{feedback.name}</span></TableCell>
                          <TableCell width='15%'><span className='font-montserrat'>{feedback.email}</span></TableCell>
                          <TableCell width='20%'><span className='font-montserrat'>{feedback.subject}</span></TableCell>
                          <TableCell width='30%'><span className='font-montserrat'>{feedback.message}</span></TableCell>
                          <TableCell width='10%'><span className='font-montserrat'>{moment(feedback.createdAt).format('DD MMM, YYYY HH:mm')}</span></TableCell>
                          <TableCell width='10%'>
                            <IconButton edge='start' color='inherit' onClick={() => this.onReplyFeedback(feedback)}>
                              <ReplyIcon htmlColor={cyan[800]} />
                            </IconButton>
                            <IconButton className='ml-2' edge='start' color='inherit' onClick={() => this.onDeleteFeedback(feedback)}>
                              <DeleteIcon htmlColor={pink[600]} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      count={feedbacks.length}
                      rowsPerPage={rowsPerPage}
                      page={activePage}
                      onChangePage={this.onChangePage}
                      onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        }
        {
          showDeleteFeedbackModal &&
          <AlertModal
            isOpen={showDeleteFeedbackModal}
            title='Delete Feedback'
            message='Are you sure you want to remove this feedback?'
            handleClose={() => this.setState({ showDeleteFeedbackModal: false })}
            onClickPositive={this.onConfirmDeleteFeedback} />
          }
          {
            showReplyModal &&
            <ReplyModal
              isOpen={showReplyModal}
              handleClose={() => this.setState({ showReplyModal: false })}
              onClickSend={this.onSendReply}
              feedback={activeFeedback}
              />
          }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(Dashboard);
