import React from 'react';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: '450px',
  },
}));

const ReplyModal = ({ isOpen, handleClose, feedback, onClickSend }) => {
  const classes = useStyles();
  const [reply, setReply] = React.useState('');

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <h4 id="transition-modal-title">Send reply</h4>
          <div className='my-3'>
            <TextField
              disabled
              id="filled-disabled"
              label="Name"
              className={classes.textField}
              defaultValue={feedback.name}
              variant="filled"
            />
          </div>
          <div className='my-3'>
            <TextField
              disabled
              id="filled-disabled"
              label="Email"
              className={classes.textField}
              defaultValue={feedback.email}
              variant="filled"
            />
          </div>
          <div className='my-3'>
            <TextField
              disabled
              id="filled-disabled"
              label="Subject"
              className={classes.textField}
              defaultValue={feedback.subject}
              variant="filled"
            />
          </div>
          <div className='my-3'>
            <TextField
              disabled
              id="filled-disabled"
              label="Message"
              className={classes.textField}
              defaultValue={feedback.message}
              variant="filled"
              multiline
            />
          </div>
          <div className='my-3'>
            <TextField
              label="Reply"
              className={classes.textField}
              placeholder='Write a reply message'
              value={reply}
              onChange={event => setReply(event.target.value)}
              multiline
              variant="outlined"
            />
          </div>
          <div className='row pull-right'>
            <Button color='secondary' variant="contained" onClick={() => onClickSend(reply)}>Send</Button>
            <Button onClick={handleClose} className='ml-2'>Cancel</Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default ReplyModal;
