import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton
} from '@material-ui/core';
import { pink, cyan } from '@material-ui/core/colors';
import AddCouponModal from './modals/AddCouponModal';
import { AlertModal } from './modals/AlertModal';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from 'reactstrap-button-loader';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import _ from 'lodash';
import APIs from 'APIs';

export default class Coupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      deletingCoupon: false,
      rowsPerPage: 10,
      activePage: 0,
      coupons: [],
      showCouponModal: false,
      showDeleteCouponModal: false,
      activeCoupon: undefined
    }
  }

  componentDidMount() {
    this.getCoupons();
  }

  getCoupons = () => {
    this.setState({ isLoading: true });
    APIs.getCoupons().then(resp => {
      this.setState({ isLoading: false });
      if (resp.data.success) {
        this.setState({ coupons: _.orderBy(resp.data.data, ['createdAt'], ['desc']) });
      }
    }).catch(err => {
      this.setState({ isLoading: false });
    });
  }

  onChangePage = (event, newPage) => {
    this.setState({ activePage: newPage });
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), activePage: 0, offset: 0 });
  }

  showCouponModal = () => {
    this.setState({ showCouponModal: true });
  }

  onEditCoupon = (coupon) => {
    this.setState({ showCouponModal: true, activeCoupon: coupon });
  }

  onDeleteCoupon = (coupon) => {
    this.setState({ showDeleteCouponModal: true, activeCoupon: coupon });
  }

  onConfirmRemoveCoupon = () => {
    let { activeCoupon, coupons } = this.state;
    if (activeCoupon) {
      this.setState({ deletingCoupon: true, activeCoupon: undefined });
      APIs.deleteCoupon({ id: activeCoupon.id, stripeCouponId: activeCoupon.stripeCouponId }).then(resp => {
        this.setState({ deletingCoupon: false });
        _.remove(coupons, c => c.id === activeCoupon.id);
        this.setState({ coupons, showDeleteCouponModal: false });
      }).catch(err => {
        this.setState({ deletingCoupon: false, showDeleteCouponModal: false });
        console.log('Delete coupon: ', err);
      })
    }
  }

  onCouponAdded = (coupon) => {
    let { coupons } = this.state;
    coupons = [coupon, ...coupons];
    this.setState({ coupons, showCouponModal: false });
  }

  onCouponEdited = (coupon) => {
    let { coupons } = this.state;
    const index = _.findIndex(coupons, c => c.id === coupon.id);
    if (index !== -1) {
      coupons.splice(index, 1, coupon);
      this.setState({ coupons, showCouponModal: false });
    }
  }

  onShowCouponUsage = (coupon) => {
    if (this.props.onShowCouponUsage) {
      this.props.onShowCouponUsage(coupon);
    }
  }

  render() {
    const {
      isLoading,
      rowsPerPage,
      activePage,
      coupons,
      activeCoupon,
      deletingCoupon,
      showCouponModal,
      showDeleteCouponModal
    } = this.state;
    return (
      <div>
        <Button className='my-4 bg-dark-blue text-white border-0 ml-auto d-flex'
          onClick={this.showCouponModal}>
          Add Coupon
        </Button>
        <LoadingOverlay active={isLoading} spinner>
          <TableContainer className='border border-1'>
            <Table>
              <TableHead className='bg-secondary'>
                <TableRow>
                {
                  _.map(['Name', 'Coupon', 'Percent Off', 'Start Date', 'End Date', 'Uses', 'Status', 'Actions'], (field) => (
                    <TableCell key={field}>{field}</TableCell>
                  ))
                }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  coupons.length === 0 &&
                  <TableRow>
                    <TableCell>No record found</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                }
                {
                  (rowsPerPage > 0 ?
                    coupons.slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage) : coupons
                  ).map((coupon, index) => (
                    <TableRow key={coupon.id} style={{ cursor: 'pointer' }}>
                      <TableCell onClick={() => this.onShowCouponUsage(coupon)}>{coupon.name}</TableCell>
                      <TableCell onClick={() => this.onShowCouponUsage(coupon)}>{coupon.coupon}</TableCell>
                      <TableCell onClick={() => this.onShowCouponUsage(coupon)}>{coupon.percentOff}</TableCell>
                      <TableCell onClick={() => this.onShowCouponUsage(coupon)}>{coupon.startDate ? moment(coupon.startDate).format('DD MMM, YYYY') : 'No start date'}</TableCell>
                      <TableCell onClick={() => this.onShowCouponUsage(coupon)}>{coupon.endDate ? moment(coupon.endDate).format('DD MMM, YYYY') : 'No expiration'}</TableCell>
                      <TableCell onClick={() => this.onShowCouponUsage(coupon)}>{coupon.uses}</TableCell>
                      <TableCell onClick={() => this.onShowCouponUsage(coupon)}>{coupon.status}</TableCell>
                      <TableCell>
                        <IconButton edge='start' color='inherit' onClick={() => this.onEditCoupon(coupon)}>
                          <EditIcon htmlColor={cyan[800]} />
                        </IconButton>
                        <IconButton className='ml-2' edge='start' color='inherit' onClick={() => this.onDeleteCoupon(coupon)}>
                          <DeleteIcon htmlColor={pink[600]} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={coupons.length}
                    rowsPerPage={rowsPerPage}
                    page={activePage}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </LoadingOverlay>
        {
          showCouponModal &&
          <AddCouponModal
            isOpen={showCouponModal}
            toggle={() => this.setState({ showCouponModal: false, activeCoupon: undefined })}
            onCouponAdded={this.onCouponAdded}
            onCouponEdited={this.onCouponEdited}
            coupon={activeCoupon}
            />
        }
        {
          showDeleteCouponModal &&
          <AlertModal
            isOpen={showDeleteCouponModal}
            title='Delete'
            message='Are you sure to remove this coupon?'
            loading={deletingCoupon}
            onClkPosBtn={this.onConfirmRemoveCoupon}
            onClkNegBtn={() => this.setState({ showDeleteCouponModal: false, activeCoupon: undefined })}
            />
        }
      </div>
    )
  }
}
