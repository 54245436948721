import React from "react";
import { Paper } from "@material-ui/core";
import { cyan } from "@material-ui/core/colors";
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis } from "recharts";

const MonthlyNewUsers = props => {
  const styles = {
    paper: {
      backgroundColor: cyan[600],
      height: 350
    },
    div: {
      height: 300,
      padding: "5px 15px 0 15px"
    },
    header: {
      color: 'white',
      backgroundColor: cyan[500],
      padding: 10
    }
  };

  return (
    <Paper style={styles.paper}>
      <div className='font-montserrat' style={{ ...styles.header }}>New Registered Users</div>
      <div style={styles.div}>
        <ResponsiveContainer>
          <LineChart data={props.data}>
            <Line
              type="monotone"
              dataKey="count"
              stroke={cyan[400]}
              strokeWidth={2}
            />
            <XAxis
              dataKey="name"
              stroke="none"
              tick={{ fill: 'white', fontFamily: 'Montserrat' }} />
            <YAxis
              dataKey="count"
              stroke="none"
              tick={{ fill: 'white', fontFamily: 'Montserrat' }}
              allowDecimals={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};


export default MonthlyNewUsers;
