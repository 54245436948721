import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination
} from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import moment from 'moment';
import APIs from 'APIs';

const defaultState = {
  isLoading: false,
  usages: [],
  activePage: 0,
  rowsPerPage: 10
}
export default class CouponUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    const { coupon } = this.props;
    if (coupon) {
      this.getCouponUsages(coupon.stripeCouponId);
    }
  }

  getCouponUsages = (stripeCouponId) => {
    this.setState({ isLoading: true });
    APIs.getCouponUsages({ stripeCouponId }).then(resp => {
      this.setState({ usages: resp.data.data, isLoading: false });
    }).catch(err => {
      this.setState({ isLoading: false });
    })
  }

  onChangePage = (event, newPage) => {
    this.setState({ activePage: newPage });
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), activePage: 0, offset: 0 });
  }

  render() {
    const { isLoading, usages, activePage, rowsPerPage } = this.state;
    const { coupon } = this.props;
    return (
      <>
        <h4 className='mt-4'>Coupon code: {coupon.coupon}</h4>
        <LoadingOverlay active={isLoading} spinner>
          <TableContainer className='border border-1'>
            <Table>
              <TableHead className='bg-secondary'>
                <TableRow>
                  {
                    _.map(['ID', 'User Name', 'User Email',  'Usage Date'], field => (
                      <TableCell key={field}>{field}</TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  usages.length === 0 &&
                  <TableRow>
                    <TableCell>No record found</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                }
                {
                  (
                    rowsPerPage > 0 ?
                    usages.slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage) : usages
                  ).map((usage, index) => (
                    <TableRow key={usage.id}>
                      <TableCell>{index + 1 + (rowsPerPage * activePage)}</TableCell>
                      <TableCell>{usage.name}</TableCell>
                      <TableCell>{usage.email}</TableCell>
                      <TableCell>{moment(usage.createdAt).format('DD MMM, YYYY HH:mm')}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={usages.length}
                    rowsPerPage={rowsPerPage}
                    page={activePage}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </LoadingOverlay>
      </>
    )
  }
}
