import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import APIs from 'APIs';
import _ from 'lodash';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';

const FILTER_ITEM_HEIGHT = 48;
const defaultState = {
  offset: 0,
  users: [],
  totalUserCount: 0,
  rowsPerPage: 10,
  activePage: 0,
  planFilterAnchorEl: null,
  balanceFilterAnchorEl: null,
  freeTrialFilterAnchorEl: null,
  subscribeFilterAnchorEl: null,
  dateFilterAnchorEl: null,
  isLoading: false,
  filterCriteria: {
    createdDate: ''
  }
};
export default class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    const { offset, rowsPerPage } = this.state;
    this.getUsers(offset, rowsPerPage);
  }

  getUsers = (offset, limit) => {
    const { totalUserCount, filterCriteria } = this.state;
    if (totalUserCount !== 0 && offset >= totalUserCount) {
      return;
    }
    this.setState({ isLoading: true });
    APIs.getUsers({ offset, limit, filter: filterCriteria }).then(resp => {
      const users = resp.data.data;
      _.forEach(users, user => {
        if (user.twilioAccountSid && user.twilioAuthToken) {
          this.getTwilioBalance(user.id);
        }
      })
      this.setState({
        isLoading: false,
        offset: offset + resp.data.data.length,
        users: offset ? [...this.state.users, ...users] : users,
        totalUserCount: resp.data.totalUserCount ?? this.state.totalUserCount
      });
    }).catch(err => {
      this.setState({ isLoading: false });
    });
  }

  getTwilioBalance = (userId) => {
    const params = {
      userId
    };
    APIs.getTwilioBalanceWithoutAuth(params).then(resp => {
      const { balance, currency } = resp.data.data;
      let { users } = this.state;
      const index = _.findIndex(users, user => user.id === userId);
      if (index !== -1) {
        users[index].twilioCurrency = currency;
        users[index].twilioBalance = balance;
        this.setState({ users });
      }
    }).catch(err => {
      console.log('Get twilio balance: ', err);
      let message = err.message;
      if (err.response) {
        message = err.response.data.message;
      }
      this.setState({ alert: { type: 'danger', message: 'Problem with your twilio. ' + message } });
    });
  }

  onChangePage = (event, newPage) => {
    const { activePage } = this.state;
    if (newPage > activePage) {
      this.setState({ activePage: newPage }, () => {
        const { offset, rowsPerPage } = this.state;
        this.getUsers(offset, rowsPerPage);
      });
    } else {
      this.setState({ activePage: newPage });
    }
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), activePage: 0, offset: 0 }, () => {
      const { offset, rowsPerPage } = this.state;
      this.getUsers(offset, rowsPerPage);
    });
  }

  getSymbolFromPriceUnit = (currency) => {
    var currency_symbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
    };

    if(currency_symbols[currency] !== undefined) {
        return currency_symbols[currency];
    }
    return '$';
  }

  renderPlanFilterMenu = () => {
    const { planFilterAnchorEl } = this.state;
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => this.setState({ planFilterAnchorEl: event.currentTarget })}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={planFilterAnchorEl}
          keepMounted
          open={Boolean(planFilterAnchorEl)}
          onClose={() => this.setState({ planFilterAnchorEl: null })}
          PaperProps={{
            style: {
              maxHeight: FILTER_ITEM_HEIGHT * 4.5,
            },
          }}>
          {
            _.map(['Monthly', 'Quarterly', 'Bi-yearly'], (plan) => {
              return (
                <MenuItem key={plan}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={_.includes(this.state.filterCriteria.subscribedPlan, plan)}
                        onChange={event => {
                          let { subscribedPlan } = this.state.filterCriteria;
                          if (event.target.checked) {
                            subscribedPlan.push(plan);
                          } else {
                            _.remove(subscribedPlan, i => i === plan);
                          }
                          this.setState({
                            filterCriteria: {
                              ...this.state.filterCriteria,
                              subscribedPlan
                            },
                            offset: 0,
                            activePage: 0
                          }, () => {
                            this.getUsers(0, this.state.rowsPerPage);
                          });
                        }} />
                    }
                    label={plan}
                    />
                </MenuItem>
              )
            })
          }
        </Menu>
      </>
    )
  };

  renderFilterMenu = (anchorEl, options) => {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className='p-1'
          onClick={(event) => {
            let state = this.state;
            state[anchorEl] = event.currentTarget;
            this.setState(state);
          }}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state[anchorEl]}
          keepMounted
          open={Boolean(this.state[anchorEl])}
          onClose={() => {
            let state = this.state;
            state[anchorEl] = null;
            this.setState(state);
          }}
          PaperProps={{
            style: {
              maxHeight: FILTER_ITEM_HEIGHT * 4.5,
            },
          }}>
          {
            _.map(options, (option) => {
              return (
                <MenuItem
                  key={option}
                  label={option}
                  onClick={() => {
                    let state = this.state;
                    if (anchorEl === 'dateFilterAnchorEl') {
                      state['filterCriteria']['createdDate'] = option;
                    } else if (anchorEl === 'balanceFilterAnchorEl') {
                      state['filterCriteria']['callersuite_balance'] = option;
                    } else if (anchorEl === 'freeTrialFilterAnchorEl') {
                      state['filterCriteria']['free_trial'] = option;
                    } else if (anchorEl === 'usedStorageFilterAnchorEl') {
                      state['filterCriteria']['used_storage'] = option;
                    }
                    state[anchorEl] = null;
                    state['offset'] = 0;
                    state['activePage'] = 0;
                    this.setState(state, () => {
                      this.getUsers(0, this.state.rowsPerPage);
                    });
                  }}>
                  {option}
                </MenuItem>
              )
            })
          }
        </Menu>
      </>
    )
  }

  renderBalanceFilterMenu = () => {
    return this.renderFilterMenu('balanceFilterAnchorEl', ['Positive', 'Negative', 'Zero', 'All']);
  }

  renderFreeTrialFilterMenu = () => {
    return this.renderFilterMenu('freeTrialFilterAnchorEl', ['Yes', 'No', 'All']);
  }

  renderUsedStorageFilterMenu = () => {
    return this.renderFilterMenu('usedStorageFilterAnchorEl', ['Over 0 MB', 'Over 1 GB', 'Over 3 GB', 'All']);
  }

  renderDateFilterMenu = () => {
    return this.renderFilterMenu('dateFilterAnchorEl', ['This Month', 'This Week', 'Today', 'Yesterday', 'Last 7 days', 'Last 14 days', 'Last 30 days', 'All']);
  }

  render() {
    const { users, totalUserCount, rowsPerPage, activePage, isLoading, filterCriteria } = this.state;
    return (
      <div>
        <LoadingOverlay active={isLoading} spinner>
          <TableContainer className='border border-1 my-5'>
            <Table>
              <TableHead className='bg-secondary'>
                <TableRow>
                  {_.map(['ID', 'Name', 'Email', 'CallerSuite Balance', 'Twilio Balance', 'Free Trial', 'Storage Usage', 'Created Date'], (field) => {
                    return (
                      <TableCell key={field}>
                        {field}
                        {
                          field === 'CallerSuite Balance' &&
                          this.renderBalanceFilterMenu()
                        }
                        {
                          field === 'CallerSuite Balance' && filterCriteria['callersuite_balance'] && filterCriteria['callersuite_balance'] !== 'All' &&
                          <div>
                            <small>
                            {filterCriteria['callersuite_balance']}
                            </small>
                          </div>
                        }
                        {
                          field === 'Free Trial' &&
                          this.renderFreeTrialFilterMenu()
                        }
                        {
                          field === 'Free Trial' && filterCriteria['free_trial'] && filterCriteria['free_trial'] !== 'All' &&
                          <div>
                            <small>
                              {filterCriteria['free_trial']}
                            </small>
                          </div>
                        }
                        {
                          field === 'Storage Usage' &&
                          this.renderUsedStorageFilterMenu()
                        }
                        {
                          field === 'Storage Usage' && filterCriteria['used_storage'] && filterCriteria['used_storage'] !== 'All' &&
                          <div>
                            <small>
                              {filterCriteria['used_storage']}
                            </small>
                          </div>
                        }
                        {
                          field === 'Created Date' &&
                          this.renderDateFilterMenu()
                        }
                        {
                          field === 'Created Date' && filterCriteria['createdDate'] && filterCriteria['createdDate'] !== 'All' &&
                          <div>
                            <small>
                            {filterCriteria['createdDate']}
                            </small>
                          </div>
                        }
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  users.length === 0 &&
                  <TableRow>
                    <TableCell>No record found</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                }
                {
                  (rowsPerPage > 0 ?
                    users.slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage )
                    : users
                  ).map((user, index) => {
                    return (
                      <TableRow key={user.id}>
                        <TableCell>{index + 1 + activePage * rowsPerPage}</TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{this.getSymbolFromPriceUnit(user.balance)} {user.balance}</TableCell>
                        <TableCell>{user.twilioBalance ? this.getSymbolFromPriceUnit(user.twilioCurrency) : ''} {user.twilioBalance ? user.twilioBalance : ''}</TableCell>
                        <TableCell>{user.freeTrial ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{parseFloat(user.usedStorage).toFixed(2)} MB</TableCell>
                        <TableCell>{moment(user.createdAt).format('DD MMM, YYYY')}</TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
              <TableFooter className='bg-secondary'>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={totalUserCount}
                    rowsPerPage={rowsPerPage}
                    page={activePage}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </LoadingOverlay>
      </div>
    )
  }
}
