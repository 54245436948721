import React from "react";
import { Drawer, MenuItem, IconButton, ListItemIcon, ListItemText, MenuList, Divider } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { grey } from "@material-ui/core/colors";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    height: window.innerHeight,
    backgroundColor: grey[200],
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
}));
const LeftDrawer = props => {
  let { navDrawerOpen, handleDrawerClose, onMenuChanged } = props;

  const classes = useStyles();

  return (
    <Drawer anchor='left' open={navDrawerOpen} variant="permanent" classes={{ paper: clsx(classes.drawerPaper, !navDrawerOpen && classes.drawerPaperClose ) }}>
      {/*<div style={styles.logo}>Material Admin<div>*/}
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <MenuList>
        {props.menus.map((menu, index) => (
          <MenuItem
            key={index}
            onClick={() => onMenuChanged(menu)}
          >
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText className='text-dark'><span className='font-montserrat'>{menu.text}</span></ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Drawer>
  );
};

export default LeftDrawer;
