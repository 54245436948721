import React from 'react';
import {
  Alert,
  Input,
  InputGroup,
  FormGroup,
  Form,
  FormFeedback
} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { connect } from 'react-redux';
import APIs from 'APIs';

const defaultState = {
  oldPass: {
    text: '',
    error: ''
  },
  newPass: {
    text: '',
    error: ''
  },
  confirmPass: {
    text: '',
    error: ''
  },
  changePassAlert: {
    type: '',
    message: ''
  },
  updatingPass: false
};
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  onChangePass = () => {
    const { oldPass, newPass, confirmPass } = this.state;
    if (!oldPass.text) {
      this.setState({ oldPass: { ...this.state.oldPass, error: 'Old password field cannot be empty' } });
      return;
    }
    if (!newPass.text) {
      this.setState({ newPass: { ...this.state.newPass, error: 'New password field cannot be empty' } });
      return;
    }
    if (!confirmPass.text) {
      this.setState({ confirmPass: { ...this.state.confirmPass, error: 'Confirm password field cannot be empty' } });
      return;
    }
    if (newPass.text !== confirmPass.text) {
      this.setState({ confirmPass: { ...this.state.confirmPass, error: 'Confirm password does not match with the new one' } });
      return;
    }
    const { user } = this.props;
    const params = {
      adminId: user.id,
      oldPass: oldPass.text,
      newPass: newPass.text,
      confirmPass: confirmPass.text
    };
    this.setState({ updatingPass: true, changePassAlert: { type: '', message: '' } });
    APIs.changeAdminPassword(params).then(resp => {
      this.setState({ updatingPass: false, changePassAlert: { type: 'success', message: 'Changed password successfully' } });
    }).catch(err => {
      let message = err.message;
      if (err.response) {
        message = err.response.data.message;
      }
      this.setState({ updatingPass: false, changePassAlert: { type: 'danger', message } });
    });
  }

  render() {
    const { oldPass, newPass, confirmPass, changePassAlert, updatingPass } = this.state;
    return (
      <div className='my-5 w-50'>
        <h4>Change Password</h4>
        <Form role="form">
          <FormGroup className="my-3">
            <InputGroup className="input-group-alternative border border-1">
              <Input
                className='font-montserrat bg-secondary'
                placeholder="Old Password"
                type="password"
                value={oldPass.text}
                onChange={event => this.setState({ oldPass: { text: event.target.value, error: '' } })}
                invalid={oldPass.error ? true : false} />
              <FormFeedback className='font-montserrat'>{oldPass.error}</FormFeedback>
            </InputGroup>
            <InputGroup className="input-group-alternative border border-1 my-3">
              <Input
                className='font-montserrat bg-secondary'
                placeholder="New Password"
                type="password"
                value={newPass.text}
                onChange={event => this.setState({ newPass: { text: event.target.value, error: '' } })}
                invalid={newPass.error ? true : false} />
              <FormFeedback className='font-montserrat'>{newPass.error}</FormFeedback>
            </InputGroup>
            <InputGroup className="input-group-alternative border border-1 my-3">
              <Input
                className='font-montserrat bg-secondary'
                placeholder="Confirm Password"
                type="password"
                value={confirmPass.text}
                onChange={event => this.setState({ confirmPass: { text: event.target.value, error: '' } })}
                invalid={confirmPass.error ? true : false} />
              <FormFeedback className='font-montserrat'>{confirmPass.error}</FormFeedback>
            </InputGroup>
          </FormGroup>
        </Form>
        {changePassAlert.message && <Alert color={changePassAlert.type}>{changePassAlert.message}</Alert>}
        <Button
          className='pull-right'
          color='danger'
          loading={updatingPass}
          disabled={updatingPass}
          onClick={this.onChangePass}>
        Change Password
        </Button>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Settings);
