import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton
} from '@material-ui/core';
import { pink, cyan } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from 'reactstrap-button-loader';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import moment from 'moment';
import APIs from 'APIs';
import AddVirtualAssistantModal from './modals/AddVirtualAssistantModal';
import { AlertModal } from './modals/AlertModal';

const defaultState = {
  isLoading: false,
  assistants: [],
  rowsPerPage: 10,
  activePage: 0,
  showAddVAModal: false,
  showDeleteVAModal: false,
  deletingVA: false
}
export default class VirtualAssistants extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    this.getVirtualAssistants();
  }

  getVirtualAssistants = () => {
    this.setState({ isLoading: true });
    APIs.getVirtualAssistants().then(resp => {
      this.setState({ assistants: resp.data.data, isLoading: false });
    }).catch(err => {
      this.setState({ isLoading: false });
      console.log(err);
    });
  }

  onChangePage = (event, newPage) => {
    this.setState({ activePage: newPage });
  }

  onChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), activePage: 0, offset: 0 });
  }

  showAddVAModal = () => {
    this.setState({ showAddVAModal: true, assistant: undefined });
  }

  onAddedVirtualAssistant = (assistant) => {
    this.setState({ assistants: [ assistant, ...this.state.assistants ], showAddVAModal: false });
  }

  onEditedVirtualAssistant = (assistant) => {
    let { assistants } = this.state;
    const index = _.findIndex(assistants, ass => ass.id === assistant.id);
    if (index !== -1) {
      assistants.splice(index, 1, assistant);
      this.setState({ assistants, showAddVAModal: false });
    }
  }

  onEditVA = (assistant) => {
    this.setState({ assistant, showAddVAModal: true });
  }

  onDeleteVA = (assistant) => {
    this.setState({ showDeleteVAModal: true, assistant });
  }

  onConfirmRemoveVA = () => {
    let { assistant, assistants } = this.state;
    if (assistant) {
      this.setState({ deletingVA: true });
      APIs.removeVirtualAssistant({ id: assistant.id }).then(resp => {
        _.remove(assistants, (ass) => ass.id === assistant.id);
        this.setState({ assistants, showDeleteVAModal: false, deletingVA: false });
      }).catch(err => {
        this.setState({ showDeleteVAModal: false, deletingVA: false });
      });
    }
  }

  render() {
    const {
      isLoading,
      assistants,
      assistant,
      rowsPerPage,
      activePage,
      showAddVAModal,
      showDeleteVAModal,
      deletingVA
    } = this.state;

    return (
      <div>
        <Button className='my-4 bg-dark-blue text-white border-0 ml-auto d-flex'
          onClick={this.showAddVAModal}>
          Add Virtual Assistant
        </Button>
        <LoadingOverlay active={isLoading} spinner>
          <TableContainer className='border border-1'>
            <Table>
              <TableHead>
                <TableRow>
                  {
                    _.map(['ID', 'Name', 'Email', 'Role', 'Created Date', 'Action'], (field) => (
                      <TableCell key={field}>
                        {field}
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  assistants.length === 0 &&
                  <TableRow>
                    <TableCell>No record found</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                }
                {
                  (rowsPerPage > 0 ?
                    assistants.slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage)
                    : assistants
                  ).map((assistant, index) => (
                      <TableRow key={assistant.id}>
                        <TableCell>{index + 1 + activePage * rowsPerPage}</TableCell>
                        <TableCell>{assistant.name}</TableCell>
                        <TableCell>{assistant.email}</TableCell>
                        <TableCell>{assistant.role}</TableCell>
                        <TableCell>{moment(assistant.createdAt).format('DD MMM, YYYY')}</TableCell>
                        <TableCell>
                          <IconButton edge='start' color='inherit' onClick={() => this.onEditVA(assistant)}>
                            <EditIcon htmlColor={cyan[800]} />
                          </IconButton>
                          <IconButton className='ml-2' edge='start' color='inherit' onClick={() => this.onDeleteVA(assistant)}>
                            <DeleteIcon htmlColor={pink[600]} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                }
              </TableBody>
              <TableFooter className='bg-secondary'>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={assistants.length}
                    rowsPerPage={rowsPerPage}
                    page={activePage}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </LoadingOverlay>
        {
          showAddVAModal &&
          <AddVirtualAssistantModal
            isOpen={showAddVAModal}
            toggle={() => this.setState({ showAddVAModal: !this.state.showAddVAModal, assistant: undefined })}
            onAddedVA={this.onAddedVirtualAssistant}
            onEditedVA={this.onEditedVirtualAssistant}
            assistant={assistant}
            onCancel={() => this.setState({ showAddVAModal: false, assistant: undefined })} />
        }
        {
          showDeleteVAModal &&
          <AlertModal
            isOpen={showDeleteVAModal}
            title='Delete'
            message='Are you sure to remove this virtual assistant?'
            loading={deletingVA}
            onClkPosBtn={this.onConfirmRemoveVA}
            onClkNegBtn={() => this.setState({ showDeleteVAModal: false, assistant: undefined })}
            />
        }
      </div>
    )
  }
}
