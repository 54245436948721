import React from "react";
import { Paper } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

class InfoBox extends React.Component {
  render() {
    const { color, title, value, Icon } = this.props;

    const styles = {
      content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: "5px 10px",
        marginLeft: 80,
        height: 80
      },
      number: {
        display: "block",
        fontWeight: 600,
        fontSize: 16,
        color: grey[800],
        marginTop: 12
      },
      text: {
        fontSize: 16,
        fontWeight: 400,
        color: grey[800]
      },
      iconSpan: {
        float: "left",
        height: 80,
        width: 80,
        textAlign: "center",
        backgroundColor: color
      },
      icon: {
        height: 48,
        width: 48,
        marginTop: 16,
        maxWidth: "100%"
      }
    };

    return (
      <Paper>
        <span style={styles.iconSpan}>
          <Icon htmlColor='white' style={styles.icon} />
        </span>

        <div style={styles.content}>
          <span className='font-montserrat' style={styles.text}>{title}</span>
          <span className='font-montserrat' style={styles.number}>{value}</span>
        </div>
      </Paper>
    );
  }
}


export default InfoBox;
